<template>
  <div v-if="!loading">
    <h1 class="main-header">
      <translate translate-context="vertical_configuration" :translate-params="{id: vertical.id, name: vertical.name}">Edit vertical  #%{id} - %{name}</translate>
    </h1>
    <Form :data=vertical @save="onSave" :save_text="$pgettext('form','Save')"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Form from "@/domain/verticalConfiguration/components/Form.vue";
import {RecordNotFound, SuccessNotification} from "@/domain/core/exception/exceptions";

export default {
  name: 'EditVerticalConfiguration',
  components: {Form},
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    try {
      await this.fetchVertical(this.$route.params.id)
    } catch (e) {
      if (e instanceof RecordNotFound) {
        await this.$router.push({name: 'verticalConfiguration.list'})
      }
    }
    this.loading = false
  },
  computed: {
    ...mapGetters('verticalConfiguration', [
      'vertical',
    ]),
  },
  methods: {
    ...mapActions('verticalConfiguration', [
      'fetchVertical',
      'updateVertical',
    ]),
    async onSave(data) {
      await this.updateVertical({id: this.vertical.id,data: data})
      await this.$router.push({name: 'verticalConfiguration.list'})
      throw new SuccessNotification(this.$pgettext('vertical_configuration', 'Vertical Configuration updated!'))
    }
  }
}
</script>